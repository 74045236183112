import * as S from './styles';

const Footer = () => {
  return (
    <>
      <footer
        className='site-footer'
        id='colophon'
        itemType='https://schema.org/WPFooter'
        itemScope='itemscope'
        itemID='#colophon'>
        <div
          className='site-primary-footer-wrap ast-builder-grid-row-container site-footer-focus-item ast-builder-grid-row-full ast-builder-grid-row-tablet-full ast-builder-grid-row-mobile-full ast-footer-row-stack ast-footer-row-tablet-stack ast-footer-row-mobile-stack'
          data-section='section-primary-footer-builder'>
          <div className='ast-builder-grid-row-container-inner'>
            <div className='ast-builder-footer-grid-columns site-primary-footer-inner-wrap ast-builder-grid-row'>
              <div className='site-footer-primary-section-1 site-footer-section site-footer-section-1'>
                <aside
                  className='footer-widget-area widget-area site-footer-focus-item footer-widget-area-inner'
                  data-section='sidebar-widgets-footer-widget-1'
                  aria-label='Footer Widget 1'>
                  <section
                    id='block-7'
                    className='widget widget_block widget_text'>
                    <p className='has-text-align-center'>
                      Lesznowola, miejsc. Zgorzała, ul. Rajskich Ptaków, nr 2,
                      lok. 2, 05-500 | Telefon: +(48) 6977 17020 | Email:
                      aleksandralucjadrohomirecka@outlook.com | ANETA
                      DROHOMIRECKA
                    </p>
                  </section>{' '}
                </aside>
                <div
                  className='ast-builder-layout-element ast-flex site-footer-focus-item ast-footer-copyright'
                  data-section='section-footer-builder'>
                  <div className='ast-footer-copyright'>
                    <p>
                      Copyright © 2024 ANETA DROHOMIRECKA | NIP: 6272453718 |
                      REGON: 021145972
                    </p>
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
